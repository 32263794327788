.main-footer {
	background: #fff;

	.container {
		max-width: 1400px;
		padding: 20px 25px;
		margin: 0 auto;
		width: 100%;

		.footer2 {
			h3 {
				text-align: center;
				color: #8B8B8B;
				font-size: 3.3rem;
			}
		}

		.footer3 {
			ul {
				list-style: none;
				padding: 0;
				margin: 0 0 10px 0;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				li {
					margin: 0 0 0 0;

					a {
						text-decoration: none;
						color: #8b8b8b;
						margin-top: 2px;
						line-height: 1.725;
						font-size: 1.6rem;
						border-right: 1px solid rgb(199 199 199);
						padding: 0 10px 0 10px;

						&:hover {
							color: var(--Primarycolor);
						}
					}
				}

				li {
					&:last-child {
						a {
							padding: 0 0 0 10px;
							border: none;
						}
					}
				}
			}

			p {
				color: #8B8B8B;
				margin-top: 2px;
				line-height: 1.625;
				font-size: 1.5rem;
			}
		}
	}



	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}


		.footer-link {
			color: #888;
		}


	}
}

@media (max-width: 992px) {
	.main-footer {
		.container {
			padding: 20px 0px;

			.footer1,
			.footer2,
			.footer3 {
				text-align: center;
			}

			.footer2 {
				margin: 20px 0;
			}

			.footer3 {
				ul {
					justify-content: center;
				}

				p {
					text-align: center;
				}
			}
		}
	}
}