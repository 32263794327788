@import "setup.css";

.vo-nav-bar {


	a {
		/* float: left;
		display: block;
		color: #f2f2f2;
		text-align: center;
		padding: 14px 16px;
		text-decoration: none;
		font-size: 17px; */
	}

	.active {
		background-color: #4CAF50;
		color: white;
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		border: none;
		outline: none;
		color: white;
		padding: 14px 16px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {
		background-color: #555;
		color: white;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}

}

@media screen and (max-width: 1023px) {
	.vo-nav-bar {
		.vo-dropdown-content {
			background-color: #9c0404;
			padding: 10px 10px 10px 10px;
		}
	}

	.vo-nav-bar.opened .nav-item {
		float: none;
		display: block;
		text-align: left;
	}


	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
		position: relative;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			display: block;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 100%;
			z-index: 9;

			li {
				display: flex;
				width: 100%;
				margin: 5px 0;
				flex-wrap: wrap;

				a {
					color: #000;
					width: 100%;
					justify-content: space-between;
				}
			}
		}
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		position: relative;
		display: block;
		text-align: left;
		right: 0;
		top: 0;
		z-index: -1;

	}

	.vo-nav-bar .vo-dropdown:hover .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar .vo-dropdown:hover .vo-dropdown-content a {
		display: block !important;
	}
}



.header-main {
	position: fixed;
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.85);
	z-index: 3;
	top: 0;
	height: 80px;
	width: 100%;
	padding: 0px 0 20px 0;

	@media (max-width: 767px) {
		padding-top: 10px;
	}

	&.affix {
		height: 90px;

		.container {
			nav {
				.vo-nav-bar {
					.navbar-area {
						margin-top: -25px;

						@media all and (min-width: 1024px) and (max-width: 1199px) {
							margin-top: -20px;
						}
					}
				}
			}
		}
	}

	.container {
		max-width: 1400px;
		width: 100%;
		padding: 0;

		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			position: relative;
			z-index: 111;
			padding: 10px 15px;

			icon-bar {
				background-color: var(--Primarycolor);
			}

			.sr-only {
				position: absolute;
				width: 1px;
				height: 1px;
				padding: 0;
				margin: -1px;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				border: 0;
			}

			.icon-bar {
				display: block;
				width: 22px;
				height: 2px;
				border-radius: 1px;
				margin: 0px 0;
			}

			.vo-nav-bar {
				width: 70%;
				margin-top: 0;

				.navbar-area {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					list-style: none;
					padding: 0;
					margin: -20px 0 0;


					li.close {
						display: none;
					}

					li {
						a {
							margin: 0 0px 0 0px;
							padding: 8px 15px 8px 15px;
							color: var(--Primarycolor);
							font-size: 2.2rem;
							line-height: 1em;
							display: flex;
							text-transform: capitalize;
							background: transparent;

							&:hover {
								background: transparent;
								color: var(--Secondarycolor);
							}

							img {
								transition: all 0.2s ease-in-out;
								width: 25px;
								height: 25px;
							}
						}

						a.active {
							color: var(--Secondarycolor);
							background: transparent;
						}
					}

					li.langde {
						padding: 7px 15px;

						a {
							font-size: 0px;
							width: 25px;
							height: 25px;
							background-repeat: no-repeat !important;
							background-position: center !important;
							background-size: 25px !important;
							background: url(RESOURCE/img/Germany.png);
							padding: 0;
						}
					}

					li.langen {
						padding: 7px 15px;

						a {
							font-size: 0px;
							background: url(RESOURCE/img/United-kingdom.png);
							width: 25px;
							height: 25px;
							background-repeat: no-repeat !important;
							background-position: center !important;
							background-size: 25px !important;
							padding: 0;
						}
					}

					li.langnl {
						padding: 7px 15px;

						a {
							background: url(RESOURCE/img/Netherlands.png);
							font-size: 0px;
							width: 25px;
							height: 25px;
							background-repeat: no-repeat !important;
							background-position: center !important;
							background-size: 25px !important;
							padding: 0;
						}
					}

				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.header-main {
		.container {
			nav {
				.vo-nav-bar.opened {
					.navbar-area {
						overflow: visible !important;

						li.close {
							display: block;
							position: absolute;
							left: -60px !important;
							opacity: 1;
							z-index: 9;
							font-size: 35px;

							a {
								i {
									font-size: 30px;
								}
							}
						}

						li {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.header-main {
		.container {
			nav {
				flex-wrap: wrap;
				padding: 0 10px;

				.vo-nav-bar {
					position: absolute;
					right: 0;
					top: 19px;
					padding: 0 10px;

					.toggle-icon {
						float: right;
						position: relative;
						color: #fff;
						background-color: transparent;
						border-radius: 50%;
						width: 27px;
						height: 20px;
						text-align: center !important;
						margin-left: 15px;
						line-height: 27px;
						display: flex !important;
						-webkit-box-align: center;
						align-items: center;
						-webkit-box-pack: center;
						justify-content: center;
						flex-wrap: wrap;
					}

					.navbar-area {
						position: absolute;
						display: none;
						width: 100%;
						background-color: #fff;
						left: 0;
						right: -250px;
						transition: .2s ease;
						opacity: 0;
					}
				}

				.vo-nav-bar.opened {
					.navbar-area {
						display: block;
						box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
						right: 0;
						max-width: 250px;
						left: unset;
						height: 100%;
						z-index: 999;
						position: fixed;
						top: 0;
						margin: 0;
						overflow: auto;
						padding: 10px 10px;
						background-color: var(--Primarycolor);
						opacity: 1;

						li {
							a {
								color: #fff !important;
								background: transparent;
							}
						}
					}

					.overlay {
						background: rgb(0 0 0 / 0.5);
						position: fixed;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
					}
				}
			}
		}
	}

	.header-main.affix {
		.container {
			nav {
				.vo-nav-bar {
					top: 0;
					margin: 25px 0 20px 0;
				}
			}
		}
	}

}

@media all and (min-width: 1024px) and (max-width: 1199px) {
	.header-main {
		.container {
			nav {
				.vo-nav-bar {
					.navbar-area {
						li {
							a {
								padding: 8px 5px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.header-main {
		.container {
			nav {
				.logo {
					.amina-aletsch-logo {
						width: 250px;
						height: auto;
					}
				}

				.vo-nav-bar {
					position: absolute;
					right: 0;
					top: 19px;
					padding: 0 10px;
				}
			}
		}
	}

	.affix .navbarheader .amina-aletsch-logo {
		margin-top: -9px;
	}
}




.navbarheader nav .icon-bar {
	background-color: var(--Primarycolor);
}

.header-main {
	&.affix-top {
		.navbarheader {
			.logo {
				img {
					height: 120px;
					width: auto;
					margin-top: 0;
					float: left;

					@media (max-width: 1499px) {
						height: 85px;
					}

					@media (max-width: 1199px) {
						height: 85px;
						margin-top: 0;
					}

					@media (max-width: 1023px) {
						height: 85px;
						margin-top: 10px;
					}

					@media (max-width: 767px) {
						height: 75px;
						margin-top: 0;
					}
				}
			}
		}
	}

	&.affix {
		padding: 20px 0 0 0;

		.navbarheader {
			.logo {
				img {
					height: 70px;
					margin-top: 5px;

					@media (max-width: 1199px) {
						height: 65px;
						margin-top: 15px;
					}


				}
			}
		}
	}
}



.affix .header-rounded {
	top: 50px;
	fill: rgba(255, 255, 255, 1);
	height: 0;
}