.booking-view {
	padding: 140px 0 20px 0;

	.form-control {
		border: 1px solid #ccc !important;
	}

	.page-header {
		margin: 0 0 20px;
	}

	h1 {
		color: #b00;
		font-size: 2.7rem;
		margin: 0 0 10px;
		line-height: 1.25;
		font-weight: 400;
	}

	.cart-details {
		h4 {
			color: var(--Primarycolor);
			font-size: 2.2rem;
			margin: 0 0 10px;
			line-height: 1.25;
			font-weight: 400;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.price-details-btn {
			font-style: normal;
			color: var(--Primarycolor);
			text-decoration: none;

			&:hover {
				color: var(--Secondarycolor);
			}
		}
	}

	.pd-form {
		label {
			font-weight: 500 !important;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			.checkbox {
				display: flex;
				align-items: center;

				.doc-box {
					margin: 0 0 0 5px;

					a {
						font-style: normal;
						color: var(--Primarycolor);
						text-decoration: none;

						&:hover {
							color: var(--Secondarycolor);
						}
					}
				}
			}

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		border-radius: 0;
		outline: none;
		padding: 0 20px;
		height: 45px;
		line-height: 45px;
		font-size: 16px;
	}

	.btn-default {
		background: transparent;
		border: 1px solid var(--Primarycolor);
		color: var(--Primarycolor);
		width: auto;
		margin: 15px 15px 0 0;

		&:hover {
			background: var(--Secondarycolor);
			color: var(--whitecolor);
		}
	}

	.btn-go {
		background: var(--Primarycolor);
		border: 1px solid var(--Primarycolor);
		color: var(--whitecolor);
		width: auto;
		margin: 15px 0 0 0;

		&:hover {
			background: var(--Secondarycolor);
			color: var(--whitecolor);
		}
	}

}