@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	/*	min-width: 0;
	min-height: 0; */
}

.flex {
	@mixin flex;
}

.layout-column {
	flex: none !important;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.doc-box {
	.body {
		div {
			padding: 0 15px;
		}
	}
}

.guestbox {
	.gb-td {
		padding: 8px 10px !important;
	}
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}

.btn {
	background-color: var(--Primarycolor);

	&:hover {
		background-color: var(--Secondarycolor);
	}
}

.guestbox {
	.btn {
		background-color: #fff;

		&:hover {
			background-color: #e6e6e6;

		}
	}
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.asd__mobile-close {
	width: auto !important;
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}

.region-bl {
	.box {
		width: 200px;
	}
}

.guest-bl {
	.box {
		width: 300px;
	}
}

.asd__mobile-close {
	top: 11px !important;
	right: 15px !important;
	width: 30px !important;
	padding: 6px 0 !important;
	text-align: center;
}

.contact-form {
	label {
		font-weight: normal;
		text-align: left !important;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.asd__wrapper--full-screen {
	z-index: 9 !important;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--Primarycolor) !important;
		opacity: 1 !important;
		color: #fff !important;

		.asd__day-button {
			color: #fff !important;
		}
	}

	.asd__day--enabled {
		background: green;
		color: #fff !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, green 70%, var(--Primarycolor) 80%) !important;
		opacity: 1 !important;

		button {
			color: #fff !important;
		}
	}

	.asd__day--disabled {
		color: rgb(86, 90, 92) !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--Primarycolor) 20%, green 30%) !important;
		opacity: 1 !important;

		button {
			color: #fff !important;
		}
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--Primarycolor) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--Primarycolor) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: #8b8b8b !important;
		opacity: 1 !important;
		color: #fff !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: #8b8b8b !important;
		opacity: 1 !important;
		color: #fff !important;
	}

	.asd__day--in-range {
		background: #8b8b8b !important;
		border: 1px double #fff !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected,
	.asd__day--hovered {
		background: #8b8b8b !important;
		color: white !important;
		border: 1px double #fff !important;
		opacity: 1 !important;
	}
}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.pad60 {
	padding: 60px 0;
}

.alert-dismissable .close,
.alert-dismissible .close {
	width: auto;
}

.hidden-de-de {
	display: none;
}

.hidden-en-en {
	display: none;
}

.hidden-nl-nl {
	display: none;
}

.cookie-dlg {
	.box {
		margin-top: 50px;
		border: 0 solid var(--Primarycolor) !important;
		border-radius: 0 !important;

			{
			.header {
				border-bottom: var(--Primarycolor);
				padding: 6px 15px;
				text-align: center;
				background: var(--Primarycolor);
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 50px;

				h4 {
					font-size: 24px;
				}
			}

			.body {
				color: #444;
				font-size: 1.5rem;
				margin: 0 0 20px;
				line-height: 1.625;
				font-family: Open Sans, sans-serif;
			}

			.footer {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: center;

				.btn {
					max-width: 165px;
					border-radius: 0;
					font-size: 16px;
					height: 40px;
					padding: 0 15px;
					-webkit-box-pack: center;
					justify-content: center;
					margin: 5px 10px;
				}

				.btn-default {
					color: var(--Primarycolor);
					background-color: #fff;
					border: 1px solid var(--Primarycolor);
					background: transparent;
				}

			}
		}
	}
}

.btn-primary {
	background: var(--Primarycolor) !important;
	color: #fff !important;
	border: 1px solid var(--Primarycolor) !important;
	border-radius: 0 !important;

	a {
		color: #fff !important;
	}
}

@media (max-width: 767px) {
	.cookie-dlg.show {
		top: 0 !important;
	}

	.cookie-dlg {
		top: 0 !important;

		.box {
			width: 90% !important;
			height: auto !important;
			margin-top: 50px !important;
		}
	}
}

button.close {
	width: 15px;
}

.mandatory-indicator {
	color: #ff2323;
}

.mb-30 {
	margin-bottom: 30px !important;
}