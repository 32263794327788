.indexpage {


	.brief {
		padding-top: 80px;
		padding-bottom: 115px;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;
		background-color: #EDEDED;


		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {
			margin-top: 40px;
			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 25px;
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
			width: 95%;
		}


	}

	.grey-bg {
		border-top: 1px solid #eaebec;
		border-bottom: 1px solid #eaebec;
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}

.home-main-content {
	padding: 50px 40px;

	.container {
		max-width: 1400px;
		width: 100%;
		padding: 0;

		.first-pera {
			max-width: 750px;
			margin: 0 auto 20px;
			text-align: center;

			h1 {
				color: var(--Primarycolor);
				font-size: 3.3rem;
				margin: 0 0 10px;
				line-height: 1.25;
				font-weight: 400;
			}

			p {
				margin: 0 0 30px;
				color: #444444;
				font-size: 2.4rem;
				line-height: 1.25;
				font-weight: 400;
			}
		}

		.boxes-area {
			.box {
				.one-third {
					width: 100%;

						{
						.img-thumbnail {
							padding: 0;
							margin: 0;
							border: none;
							border-radius: 0;
						}

						.content-child {
							padding: 25px 30px 5px 30px;

							h2 {
								color: var(--Primarycolor);
								font-size: 2.7rem;
								margin: 0 0 10px 0;
								line-height: 1.25;
								font-weight: 400;
							}

							p {
								color: #444444;
								font-size: 1.5rem;
								margin: 0 0 20px;
								line-height: 1.625;
								font-family: 'Open Sans', sans-serif;

								a {
									padding: 6px 12px;
									color: #FFFFFF;
									background-color: var(--Primarycolor);
									border-radius: 5px;
									margin-bottom: 5px;
									display: inline-block;
									cursor: pointer;

									&:hover {
										background-color: var(--Secondarycolor);
									}
								}
							}

							ul {
								margin: 0 0 20px;
								padding: 0;

								li {
									position: relative;
									list-style: none;
									padding: 0 0 0 20px;
									color: #444444;
									font-size: 1.5rem;
									line-height: 1.625;
									font-family: 'Open Sans', sans-serif;

									&:before {
										position: absolute;
										font-family: FontAwesome;
										font-size: 0.75rem;
										line-height: 0;
										content: "\f0c8";
										color: #AFC6C6;
										margin: 13px 0 0 -18px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.home-main-content {
		padding: 50px 10px;

		.container {
			.boxes-area {
				.box {
					padding: 0;
					margin: 0 0 0 0;

					.one-third {}
				}
			}
		}
	}

	.main-footer {
		.container {
			.footer2 {
				margin: 20px 0;
			}

			.footer3 {
				p {
					margin: 0;
					text-align: center;
				}
			}
		}
	}

}

@media all and (min-width: 768px) and (max-width: 992px) {
	.home-main-content {
		.container {
			.boxes-area {
				.box {
					.one-third {
						.content-child {
							h2 {
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}