@import url('https://fonts.googleapis.com/css?family=Ropa+Sans%3A400%2C400i%7COpen+Sans%3A400%2C400i%2C600%2C600i&#038;display=auto&#038;ver=2.5.7');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--Primarycolor: #55011f;
	--Secondarycolor: #ac2925;
	--whitecolor: #fff;
	--blackcolor: #000;
	--greycolor: #b5bec3;
	--defaultcolor: #003366;
	--white: #fff;
	--cal_not_available: #cd7898;

}

body {
	font-family: 'Ropa Sans', sans-serif !important;
	background-color: #ededed !important;
}

button {
	outline: none !important;
}

a {
	text-decoration: none !important;
}