.static-view {
	h2.section-title {
		color: var(--Primarycolor);
		font-size: 3.3rem;
		text-align: center;
		line-height: 1.25;
		font-weight: 400;
		margin-bottom: 50px;
		width: 100%;
	}

	.container {
		max-width: 1400px;
		width: 100%;
		margin: 0 auto;
	}

	.carousel-default {
		position: relative;
		width: 100%;
	}

	.static-main-content {
		.row {
			margin: 0;
		}
	}

	.inner-banner {
		.item {
			height: 600px;
			width: 100%;
			background-repeat: no-repeat !important;
			background-position: center !important;
			background-size: cover !important;
		}

		.owl-nav {
			display: none;
		}

		.owl-dots {
			display: none;
		}
	}

	&.aktivities-content {
		.inner-banner {
			.item {
				height: 445px;

				@media (max-width: 767px) {
					height: 375px;
				}
			}
		}
	}

	.activity {

		.section-title {
			@media (max-width: 767px) {
				margin-top: 0 !important;

			}
		}

		.inner-banner {
			.item {
				height: 350px;
			}

			.slide1 {
				background-image: url(RESOURCE/img/aktivitaten-a.jpg);
			}

			.slide2 {
				background-image: url(RESOURCE/img/aktivitaten-b.jpg);

				@media (min-width: 993px) {
					background-position: 0 40% !important;
				}
			}

			.slide3 {
				background-image: url(RESOURCE/img/aktivitaten-c.jpg);
			}
		}

		.activity-content {
			padding: 50px 40px;

			.row {
				.box-area {
					.box {
						background: #fff;
						padding: 30px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						ul {
							list-style: none;
							padding: 0;
							margin: 0;

							li {
								font-size: 1.5rem;
								line-height: 1.625;
								font-family: 'Open Sans', sans-serif;
								display: flex;
								align-items: center;
								color: #444444;
								position: relative;
								padding: 0 0 0 20px;

								.fas {
									font-size: 7px;
									color: #afc6c6;
									margin: 0;
									position: absolute;
									left: 0;
									top: 8px;
								}

								a {
									color: var(--Primarycolor);
									text-decoration: none;
									outline: none;

									&:hover {
										color: var(--Secondarycolor);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.informationen {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/informationen-a.jpg);
			}
		}

		.informationen-content {
			padding: 50px 40px;

			.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
			}

			.box-area {
				margin: 0 0 30px 0;

				h3 {
					color: var(--Primarycolor);
					font-size: 27px;
					font-size: 2.7rem;
					line-height: 1.25;
					font-weight: 400;
					width: 100%;
					margin: 0 0 10px 0;
				}

				p {
					color: #444444;
					font-size: 1.5rem;
					font-family: 'Open Sans', sans-serif;
					margin: 0 0 20px;
					line-height: 1.625;
				}

				.button {
					padding: 6px 12px;
					color: #FFFFFF;
					background-color: var(--Primarycolor);
					border-radius: 5px;
					margin-bottom: 5px;
					display: inline-block;
					cursor: pointer;
					font-size: 1.5rem;
					line-height: 1.625;
					font-family: 'Open Sans', sans-serif;
					transition: all 0.2s ease-in-out;

					&:hover {
						background-color: var(--Secondarycolor);
					}
				}
			}
		}
	}

	.Buchungsinformation {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/informationen-a.jpg);
			}

			.slide2 {
				background-image: url(RESOURCE/img/boekingsinformatie-a.jpg);
			}

			.slide3 {
				background-image: url(RESOURCE/img/boekingsinformatie-b.jpg);
			}
		}

		.buchungsinformation-content {
			padding: 50px 40px;

			.small-width-part {
				max-width: 750px;
				margin: 0 auto 50px;

				h2 {
					margin: 0 0 20px 0;
				}

				p {
					color: #444444;
					font-size: 2.4rem;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					margin: 0 0 30px 0;
				}
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.box-area {
					.box {
						background: #fff;
						padding: 30px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;
							word-break: break-all;
						}

						ul {
							list-style: none;
							padding: 0;
							margin: 0;

							li {
								font-size: 1.5rem;
								line-height: 1.625;
								font-family: 'Open Sans', sans-serif;
								display: flex;
								align-items: center;
								color: #444444;
								position: relative;
								padding: 0 0 0 20px;

								.fas {
									font-size: 7px;
									color: #afc6c6;
									margin: 0;
									position: absolute;
									left: 0;
									top: 8px;
								}

								a {
									color: var(--Primarycolor);
									text-decoration: none;
									outline: none;

									&:hover {
										color: var(--Secondarycolor);
									}
								}
							}
						}

						h6 {
							font-size: 1.5rem;
							line-height: 1.625;
							font-family: Open Sans, sans-serif;
							display: flex;
							align-items: center;
							color: #444;
							position: relative;
							padding: 10px 0 0 0px;
						}

						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							position: relative;
							margin: 0 0 20px 0px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							&:last-child {
								margin: 0;
							}

							a {
								color: var(--Primarycolor);
								text-decoration: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}
						}

					}
				}
			}

		}
	}

	.regionen {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/regionen-a.jpg);
			}

			.slide2 {
				background-image: url(RESOURCE/img/regionen-b.jpg);
			}

			.slide3 {
				background-image: url(RESOURCE/img/regionen-c.jpg);
			}
		}

		.regionen-content {
			padding: 50px 40px;

			.small-width-part {
				max-width: 750px;
				margin: 0 auto 50px;

				h2 {
					margin: 0 0 20px 0;
				}

				p {
					color: #444444;
					font-size: 2.4rem;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					margin: 0 0 30px 0;
				}
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.box-area {
					.box {
						background: #fff;
						padding: 30px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						ul {
							list-style: none;
							padding: 0;
							margin: 0;

							li {
								font-size: 1.5rem;
								line-height: 1.625;
								font-family: 'Open Sans', sans-serif;
								display: flex;
								align-items: center;
								color: #444444;
								position: relative;
								padding: 0 0 0 20px;

								.fas {
									font-size: 7px;
									color: #afc6c6;
									margin: 0;
									position: absolute;
									left: 0;
									top: 8px;
								}

								a {
									color: var(--Primarycolor);
									text-decoration: none;
									outline: none;

									&:hover {
										color: var(--Secondarycolor);
									}
								}
							}
						}

						h4 {
							color: #444444;
							font-size: 2.1rem;
							width: 100%;
							line-height: 1.25;
							font-weight: 400;
							margin: 0 0 10px 0;
						}

						h6 {
							font-size: 1.5rem;
							line-height: 1.625;
							font-family: Open Sans, sans-serif;
							display: flex;
							align-items: center;
							color: #444;
							position: relative;
							padding: 10px 0 0 0px;
						}

						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							position: relative;
							margin: 0 0 20px 0px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							&:last-child {
								margin: 0;
							}

							a {
								color: var(--Primarycolor);
								text-decoration: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}
						}

					}
				}
			}

		}
	}

	.direction {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/route-1.jpg);
			}

			.slide2 {
				background-image: url(RESOURCE/img/route-2.jpg);
			}

			.slide3 {
				background-image: url(RESOURCE/img/route-3.jpg);
			}
		}

		.direction-content {
			padding: 50px 40px;

			.row {
				.box-area {
					.box {
						background: #fff;
						padding: 30px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						h4 {
							color: #444;
							font-size: 2.4rem;
							width: 100%;
							line-height: 1.25;
							font-weight: 400;
							margin: 0 0 10px;
						}

						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							position: relative;
							margin: 0 0 20px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							a {
								color: var(--Primarycolor);
								text-decoration: none;
								outline: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}

						}
					}
				}
			}
		}
	}

	.place-of-power {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/kraftort_binntal_header.jpg);
			}
		}

		.place-of-power-content {
			padding: 50px 40px;

			.small-width-part {
				max-width: 750px;
				margin: 0 auto 50px;

				h2 {
					margin: 0 0 20px 0;
				}

				p {
					color: #444444;
					font-size: 2.4rem;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					margin: 0 0 30px 0;
				}
			}

			.row {
				.box-area {
					.box {
						background: #fff;
						padding: 0px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						h4 {
							color: #444;
							font-size: 2.1rem;
							width: 100%;
							line-height: 1.25;
							font-weight: 400;
							margin: 0 0 10px;
						}

						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							position: relative;
							margin: 0 0 20px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							a {
								color: var(--Primarycolor);
								text-decoration: none;
								outline: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}

						}
					}
				}
			}
		}
	}

	.media {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/media-1.jpg);
			}
		}

		.media-content {
			padding: 50px 40px;

			.small-width-part {
				max-width: 750px;
				margin: 0 auto 50px;
				text-align: center;

				h2 {
					margin: 0 0 20px 0;
				}

				p {
					color: #444444;
					font-size: 2rem;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					margin: 10px 0px;
					display: flex;
					align-items: center;
					justify-content: center;

					a {
						color: var(--Primarycolor);
					}
				}
			}

			.row {
				.section-title {
					margin: 0 0 20px 0;
				}

				.box-area {
					.box {
						background: #fff;
						padding: 0px;
						margin: 0 0 70px 0;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						h4 {
							color: #444;
							font-size: 2.1rem;
							width: 100%;
							line-height: 1.25;
							font-weight: 400;
							margin: 0 0 10px;
						}

						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							position: relative;
							margin: 0 0 20px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							a {
								color: var(--Primarycolor);
								text-decoration: none;
								outline: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}

						}
					}
				}
			}
		}
	}

	.links-page {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/informationen-a.jpg);
			}
		}

		.links-page-content {
			padding: 50px 40px;

			p {
				color: #444444;
				font-family: 'Open Sans', sans-serif;
				font-size: 15px;
				font-size: 1.5rem;
				line-height: 1.625;
				text-align: center;

				a {
					color: var(--Primarycolor);
					text-decoration: none;
					outline: none;

					&:hover {
						color: var(--Secondarycolor);
					}
				}
			}
		}
	}

	.contact-page {
		.inner-banner {
			.item {}

			.slide1 {
				background-image: url(RESOURCE/img/contact-1.jpg);
			}

			.slide2 {
				background-image: url(RESOURCE/img/contact-2.jpg);
			}
		}

		.contact-page-content {
			padding: 50px 40px;

			.small-width-part {
				max-width: 750px;
				margin: 0 auto 50px;

				h2 {
					margin: 0 0 20px 0;
				}

				.sub-section-title {
					color: #444444;
					font-size: 2.4rem;
					text-align: center;
					font-weight: 400;
					line-height: 1.25;
					margin: 0 0 30px 0;
				}

				.checkbox {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.doc-box {
						margin: 0 0 0 5px;

						a {
							font-size: 2rem;
						}
					}
				}
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.box-area {
					.box {
						background: #fff;
						padding: 30px;
						margin: 0 0 30px 0;
						text-align: center;

						h3 {
							color: var(--Primarycolor);
							font-size: 27px;
							font-size: 2.7rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 10px 0;

						}

						h4 {
							color: #444444;
							font-size: 2.4rem;
							line-height: 1.25;
							font-weight: 400;
							width: 100%;
							margin: 0 0 15px 0;
						}


						p {
							font-size: 1.5rem;
							line-height: 1.625;
							color: #444;
							font-weight: 600;
							position: relative;
							margin: 0 0 0 0px;
							padding: 0;
							width: 100%;
							font-family: Open Sans, sans-serif;

							a {
								color: var(--Primarycolor);
								text-decoration: none;

								&:hover {
									color: var(--Secondarycolor);
								}
							}
						}

					}
				}
			}

		}
	}

	.contact-form {
		form {
			.form-group {
				margin: 0 0 20px 0;

				label {
					text-align: left;
					color: #444;
					font-size: 2rem;
					line-height: 1.25;
					font-weight: 400;
					width: 100%;
					margin: 0 0 5px;
					padding: 0;
					width: 100%;
				}

				.col-sm-12 {
					padding: 0;
				}

				.form-control {
					border: 1px solid #dadada;
					height: 50px;
					border-radius: 0;
					outline: none;
					box-shadow: none;
					font-size: 16px;
				}

				textarea.form-control {
					height: 120px;
				}

				.btn-block {
					background: var(--Primarycolor) !important;
					border: var(--Primarycolor);
					height: 50px;
					font-size: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 0;
					padding: 0;

					&:hover {
						background-color: var(--Secondarycolor) !important;
					}

					i {
						margin: 0 7px 0 0;
					}
				}
			}
		}
	}
}


.checkbox {
	display: flex;
	align-items: center;
	padding-left: 25px;

	label {
		width: auto !important;
		margin: 0 0 0 0 !important;

		&:before {
			outline: none;
		}

		&:after {
			outline: none;
		}
	}

	.doc-box {
		a {
			font-size: 15px;
			color: var(--Primarycolor);
		}
	}
}

.contact-form .checkbox label:before,
.contact-form .checkbox label:after {
	top: 2px !important;
}

@media (max-width: 767px) {
	.static-view {
		.static-main-content {
			padding: 30px 0 !important;

			.row {
				margin: 0;

				.box-area {
					padding: 0;

					.box {
						margin: 0 0 20px !important;
					}
				}
			}
		}

		h2.section-title {
			margin: 50px 0;
		}

		.small-width-part {
			margin: 0 auto 0 !important;
		}
	}
}

@media all and (min-width: 768px) and (max-width: 992px) {
	.static-view {
		.static-main-content {
			padding: 50px 10px !important;
		}
	}
}