@import "common.css";

.unit-view {

	.form-control::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #555;
	}

	.form-control::-moz-placeholder {
		/* Firefox 19+ */
		color: #555;
	}

	.form-control:-ms-input-placeholder {
		/* IE 10+ */
		color: #555;
	}

	.form-control:-moz-placeholder {
		/* Firefox 18- */
		color: #555;
	}

	.mt-30 {
		margin-top: 30px !important;
	}

	.mb-30 {
		margin-bottom: 30px !important;
	}

	a {
		color: var(--Primarycolor) !important;
		text-decoration: none !important;

		&:hover {
			color: #8b8b8b !important;
			background: none !important;
		}
	}


	button {
		overflow: hidden !important;
		outline: none !important;
		border-radius: 0;
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.fav-section {
		button {
			color: #fff !important;

			i {
				margin: 0 5px 0 0;
			}
		}
	}

	.inner-banner {
		padding: 0 0 0;
		position: relative;
		height: 380px;
		overflow: hidden;

		.page-header {
			position: relative;
			z-index: 1;
			color: var(--whitecolor);
			margin-top: 0;
			border-bottom: none;

			h1 {
				font-size: 28px;

				small {
					color: rgb(255 255 255 / 55%);
				}
			}
		}

		.cover-img {
			position: absolute;
			top: 0;
			z-index: 0;
			height: 500px;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			/*background-color: rgba(0, 51, 102, .5); */
		}
	}

	.unit-list-section {
		margin: 0 0 30px 0;

		.item {
			height: 450px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.view-gallery-btn {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

		}
	}

	.unit-prop-boxes {
		.showAllProperties {
			.prop-box {
				display: block;
			}
		}

		a {
			span {
				font-size: 18px;
				text-decoration: underline;
			}
		}


		.prop-box {
			display: none;
		}

		.prop-box.first {
			display: block;
		}
	}


	.owl-carousel {
		.item {
			border-right: 5px solid #ededed;
			border-left: 5px solid #ededed;
		}

		.owl-nav {
			position: absolute;
			top: 38%;
			width: 100%;

			button {
				color: #fff !important;
				text-shadow: 1px 1px 1px #000;
				position: absolute;
				z-index: 999;
				background: transparent;
				border: 0;
				font-size: 0 !important;
				padding: 0 0 !important;
				background: rgba(0, 0, 0, .4) !important;
				line-height: 1 !important;
				overflow: hidden;
				outline: none !important;
				width: 40px;
				height: 95px;
				display: flex;
				align-items: center;
				justify-content: center;

				&.owl-next {
					right: 15px;

					span {
						font-size: 0;

						&:after {
							content: "\f054";
							font: normal normal normal 14px/1 FontAwesome;
							font-size: 38px;
							color: #fff;
						}
					}

				}

				&.owl-prev {
					left: 15px;

					span {
						font-size: 0;

						&:after {
							content: "\f053";
							font: normal normal normal 14px/1 FontAwesome;
							font-size: 38px;
							color: #fff;
						}
					}

				}
			}
		}
	}

	.unit-map {
		position: relative;

		.unit-address {
			background: rgb(130 130 130 / 80%);
			color: #fff;
			padding: 15px;
			max-width: 168px;
			position: absolute;
			top: 90px;
			width: 100%;
			left: 10px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

			h2 {
				color: #fff;
				margin: 0 0 10px 0;
			}
		}
	}

	.contact-form {
		padding: 5px;
		border: 1px solid var(--greycolor);
		margin-top: 30px;

		h2 {
			margin-top: 10px;
		}

		.form-group {
			.checkbox {
				display: flex;
				align-items: center;

				.doc-box {
					margin: 0 0 0 5px;

					a {
						font-style: normal;
						color: var(--Primarycolor);
						text-decoration: none;

						&:hover {
							color: var(--Secondarycolor);
						}
					}
				}
			}
		}
	}



	.search-section {
		.searchbox {
			margin: 0 0 12px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.unit-title {
		width: 100%;
		margin-bottom: 0;
		display: inline-block;
	}

	.unit-image-gallery {
		margin-right: -5px;
		margin-left: -5px;
		margin-top: -5px;
		display: inline-block;

		.visible-print {
			display: none !important;
		}

		.unitGallery {
			a {
				display: none;
				padding: 5px;
				height: 150px;

				&:nth-child(2),
				&:nth-child(3) {
					height: 220px;
				}

				@media (max-width: 767px) {
					padding: 5px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&:nth-child(1) {
					width: 70%;
					float: right;
					display: block;
					height: 440px;
					overflow: hidden;

					@media (max-width: 767px) {
						width: 100%;
						height: 300px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					width: 30%;
					float: left;
					display: block;

					@media (max-width: 767px) {
						width: 50%;
						height: 140px;
					}
				}

				&:nth-child(4) {
					clear: both;

					@media (max-width: 767px) {
						clear: none;
						height: 140px;
					}
				}

				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) {
					display: block;
					width: 20%;
					float: left;

					@media (max-width: 767px) {
						width: 50%;
						height: 140px;
					}
				}

				&:nth-child(8) {
					position: relative;

					&:before {
						content: "+ mehr";
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						bottom: 0;
						text-align: center;
						width: 100%;
						color: #fff;
						font-weight: 600;
						font-size: var(--font-size-md);
						margin-top: -10px;
						z-index: 1;
						padding: 0 10px;
					}

					&:after {
						content: "";
						position: absolute;
						left: 5px;
						right: 5px;
						top: 5px;
						bottom: 5px;
						background: rgba(0, 0, 0, .5);
						z-index: 0;

						@media (max-width: 767px) {
							top: 5px;
							bottom: 5px;
						}
					}

				}
			}
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		h2 {
			margin-top: 15px;
			font-size: 22px;
			color: var(--Primarycolor);
			margin-bottom: 10px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		h3 {
			border-bottom: 0px solid rgb(0 0 0 / 20%);
			padding: 0 0 10px 0;
		}

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				flex-wrap: wrap;
				padding: 0;
				position: relative;
				padding-left: 130px;

				@media (max-width: 767px) {
					padding: 0;

				}
			}

			li.first {
				width: auto;
				position: absolute;
				left: 0;

				@media (max-width: 767px) {
					position: static;
					width: 100%;
				}

			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 10px;
				margin: 5px 0;
				width: 33.33%;

				@media (max-width: 767px) {
					width: 50%;
					text-align: left;
				}

				.day {
					width: 24px;
					height: 24px;
					display: inline-block;
					margin: 0 10px 0 0;
					border: 1px solid #e5e5e5;

					&.day-free {
						background: green;
					}

					&.day-full {
						background: var(--Primarycolor);
					}

					&.day-end {
						background: linear-gradient(90deg, var(--Primarycolor) 20%, green 30%) !important;
					}

					&.day-start {
						background: linear-gradient(90deg, green 70%, var(--Primarycolor) 80%) !important;
					}

					&.day-selected {
						background: #8b8b8b !important;
					}

					&.day-no-check {
						background: #fff;
						border: 1px solid #565a5c !important;
					}

				}
			}
		}

		.main-col {

			position: static;

			.stars {
				margin: 15px 0;
				font-size: 18px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.fa {
					color: var(--Primarycolor);
				}
			}

			.description {
				padding: 0;

				p {
					font-size: 16px;
					margin: 0 0 15px 0;
				}
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 30px;

				.prop.bool {
					font-weight: bold;
				}

				.prop {
					padding: 0 15px;
					padding-left: 30px;
					position: relative;
					margin-bottom: 2px;
					width: 33.33%;

					@media (max-width: 767px) {
						width: 48%;
					}

					@media (max-width: 575px) {
						width: 100%;
					}

					&:before {
						content: "\f00c";
						color: var(--Secondarycolor);
						position: absolute;
						left: 0;
						font-family: fontawesome;
					}
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.info-block-side {
			border: 1px solid var(--Secondarycolor);

			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: var(--Primarycolor);

				text-align: center;

				h2 {
					margin-top: 10px;
					color: var(--whitecolor);
					font-size: 18px;
				}
			}

			.info-col.noaction {

				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px; 
           */



				.info-col-main {
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;

					.btn-secondary {
						color: #fff !important;
					}
				}

			}
		}


		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 10px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.subrow {
					.btn-default {
						border: 1px solid var(--Primarycolor) !important;
						background: transparent !important;
						color: var(--Primarycolor) !important;

						&:hover {
							background: ar(--Primarycolor) !important;
							color: var(--whitecolor) !important;
						}
					}

					.book-btn {
						background: var(--Primarycolor);
						border-color: var(--Primarycolor);

						&:hover {
							border: 1px solid var(--Primarycolor);
							background: transparent;
							color: var(--Primarycolor);
						}
					}
				}

				.filters {}



				.book-btn {
					margin-top: 00px;
					margin-bottom: 10px;
					height: 40px;
					padding: 0 10px;
					font-size: 15px;
					border-radius: 0;
					background: var(--Primarycolor) !important;
					border-color: var(--Primarycolor) !important;
					-webkit-transition: all .3s ease;
					transition: all .3s ease;
					width: 100% !important;

					&:hover {
						background: var(--Secondarycolor) !important;
						border-color: var(--Secondarycolor) !important;

					}
				}


				.btn-lg {
					width: 100% !important;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}


		}

		.aboutus {
			border: 1px solid #b5bec3;
			padding: 10px;

			i {
				margin-right: 5px;
				color: var(--Primarycolor);
			}

			.mt-8 {
				margin-top: 8px;
			}
		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.unit-sidebar {
		.affix {
			width: 360px;
			z-index: 1;
			background: transparent !important;
			position: fixed !important;
			top: 50px !important;
		}
	}


	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}




		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}


		.unit-sidebar {

			#unitsidebar {
				width: 100%;
				background: transparent !important;
				position: relative;
				top: unset !important;
				margin: 40px 0 0 0 !important;
				float: left;
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.unit-sidebar {

			#unitsidebar {
				width: 100%;
				background: transparent !important;
				position: relative !important;
				top: unset !important;
				margin: 40px 0 0 0 !important;
				float: left;
			}
		}

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}

		.unit-sidebar {
			#sidebar {
				width: 100%;
				z-index: 1;
				background: transparent !important;
				position: relative;
				top: unset !important;
				margin: 40px 0 0 0 !important;
				float: left;
			}
		}

	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

		.unit-sidebar {
			.affix {
				width: 293px;
			}
		}

	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

	.optionals {
		.btn-warning {
			border: 1px solid var(--Primarycolor);
			background: var(--Primarycolor);
			color: #fff;

			&:hover {
				border: 1px solid var(--Secondarycolor);
				background: var(--Secondarycolor);
			}
		}
	}

}


/*
.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

*/

.reduced-width-container {
	h2 {
		font-size: 22px;

		color: var(--Primarycolor);
	}
}

.unit-proposals {

	.single-unit {
		margin-bottom: 30px;

		>a {
			display: block;
			position: relative;

			.full-img {
				width: 100%;
			}

			h3 {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgba(0, 51, 102, .8);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				font-size: 18px;
				font-weight: 500;
				margin-top: 0;
			}

			.udetails {
				display: flex;
				text-align: center;
				-webkit-box-pack: center;
				justify-content: center;
				background-color: rgba(0, 51, 102, .8);
				flex-wrap: wrap;
				padding: 5px;

				.ft-icon {
					display: inline-flex;
					-webkit-box-align: center;
					align-items: center;
					color: #fff;
					border: 1px solid #fff;
					padding: 5px 10px;
					margin: 5px;

					i {
						margin-right: 5px;
					}
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.unit-view {
		.unit-list-section {
			.item {
				height: 320px;
			}
		}

		.owl-carousel {
			.owl-nav {
				button {
					height: 75px;
				}

				button.owl-next {
					span {
						&::after {
							font-size: 28px;
						}
					}
				}

				button.owl-prev {
					span {
						&::after {
							font-size: 28px;
						}
					}
				}
			}
		}
	}
}