@import "common.css";
@import "setup.css";

.search2 {
	z-index: 9;
}

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.search-popup-trigger,
.form-group input {
	cursor: pointer !important;
}

.guestbox {
	.gb-td {
		padding: 8px 10px !important;
	}
}

.h-100 {
	height: 100%;
}



/*.list-view {
	.map-col {
		.affix {
			@media (min-width:993px) {
				position: fixed !important;
				top: 0;
				width: 100%;
				max-width: 480px;
			}
		}
	}
}
.map-view {
	.map-col {

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
			}
		}
	}
}


.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}*/

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 0 0px;
		color: #565a5c;


		.a-color {
			color: #007da7;
		}

		.offer-box {
			padding: 10px;

			.offer-text {
				color: red;
			}

			ul {
				padding: 0 0 0 20px;
			}
		}

		.result-header {
			padding: 0px 0 0;
			border-bottom: 1px solid rgb(235 235 235 / 0.3);
			margin-bottom: 15px;

			h3 {
				color: #7b7b7b;
				font-weight: 500;
				letter-spacing: .5px;
				font-size: 24px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 25px;
				display: -webkit-box;
				display: flex;
				width: 100%;

			}

			.sorting-trigger {
				margin-bottom: 30px;
				display: flex;
				align-items: center;
				align-items: center;

				span {
					color: #7b7b7b;
					font-size: 16px;
					margin: 0 5px 0 0;

					@media (max-width:767px) {
						display: block;
						margin-bottom: 5px;
					}
				}

				.sorting-item-list {
					width: 100%;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
				}

				.sorting-item {
					border: 1px solid;
					border-radius: 1px;
					padding: 8px 20px 8px 13px;
					background: #fff;
					border-color: #e6eff2;
					color: #a9a9a9;
					font-weight: 400;
					font-size: 14pt;
					letter-spacing: .04em;
					-webkit-transition: all ease 0.3s;
					transition: all ease 0.3s;
					display: inline-block;
					line-height: 1.82857143;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					-ms-touch-action: manipulation;
					touch-action: manipulation;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					text-decoration: none;
					display: flex;
					align-items: center;
					margin: 5px;

					&:hover {
						color: var(--Primarycolor);
					}


					&.icon-grid {
						&:before {
							content: "\f00a";
							font-family: fontawesome;
							margin-right: 15px;
						}
					}

					&.icon-ascend {
						&:before {
							content: "\f15d";
							font-family: fontawesome;
							margin-right: 15px;
						}
					}

					&.icon-tag {
						&:before {
							content: "\f02b";
							font-family: fontawesome;
							margin-right: 15px;
						}
					}

					&.icon-star {
						&:before {
							content: "\f005";
							font-family: fontawesome;
							margin-right: 15px;
						}
					}
				}

				.sorting-item.activesorting {
					color: var(--Primarycolor);
				}

				.price-up {
					&:after {
						content: "\f160";
						font-family: fontawesome;
						margin: 0 0 0 10px;
					}
				}

				.price-down {
					&:after {
						content: "\f885";
						font-family: fontawesome;
						margin: 0 0 0 10px;
					}
				}
			}


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 0 0;
		height: 90vh !important;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}

		.gm-style .gm-style-iw-c {
			max-width: 330px !important;
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		h4 {
			margin: 5px 0 10px 0;
		}

		.preview-img {
			min-height: 150px;
			position: relative;

			img {
				width: 100%;
			}

			.ratings {
				position: absolute;
				right: 13px;
				bottom: 5px;
				width: auto;
				background-color: rgb(187 0 0/.75);
				color: #fff;
				padding: 3px 5px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

		.search-result-properties {
			flex-wrap: wrap;
		}
	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {
		padding: 0;
		display: flex;
		flex-wrap: wrap;


		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;


			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;
					}

					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--Primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}
				}
			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}

	.map-view {
		#sidebar {
			position: relative;
			top: 0 !important;

			.map-pane {
				padding: 0;
			}
		}
	}

	.search-result-properties {
		display: flex;
		background-color: var(--Primarycolor);
		background-color: #ffff;
		padding: 0 5px;
		min-height: 45px;
		overflow: hidden;
		margin-bottom: 10px;

		.ft-icon {
			text-align: center;
			display: -webkit-inline-box;
			display: inline-flex;
			-webkit-box-align: center;
			align-items: center;
			color: #5c5c5c;
			border: 1px solid #5c5c5c;
			padding: 3px 6px;
			margin: 5px;
			font-weight: 700;

			.ft-title {
				margin-left: 5px;
			}
		}

		@media (max-width: 767px) {
			height: auto;
		}
	}

	.alternativesPrice {
		padding: 0 0 10px 10px;
		text-align: left;

		h5 {
			font-size: 16px;
		}

		a {
			color: #444;
			font-size: 16px;
		}

		a+font {
			color: #7c7c7c;
		}
	}

	.unit-btn {
		a.btn {
			/* background: var(--blackcolor); */
			background: url(RESOURCE/img/suchen-bg-1.jpg);
			color: var(--whitecolor);
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0.5px;
			padding: 10px 10px;
			border-radius: 0;
			/*text-transform: uppercase;*/
			-webkit-transition: all ease 0.3s;
			transition: all ease 0.3s;
			background-position: 0 -100px;
			text-shadow: 1px 5px 15px rgb(0 0 0);
			position: relative;
			z-index: 1;
			transition: 0.3s;

			&:hover {
				&:before {
					background: rgb(0 0 0 / 40%);
				}

			}

			&:before {
				display: block;
				content: " ";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				background: rgb(0 0 0 / 25%);
				transition: 0.3s;
			}
		}
	}

	.search-bar {
		padding: 135px 0 30px 0;
		background: url(RESOURCE/img/search-banner.jpg);
		background-position: 50% 50%;
		background-size: cover;
		position: relative;

		.box {
			/*max-width: 200px;
			width: 100%;*/
		}

		.search-top-box {
			display: flex;
			flex-wrap: wrap;

			.left {
				width: 70%;
				padding-right: 15px;

				@media (max-width: 991px) {
					width: 100%;
					padding-right: 0;
				}
			}

			.right {
				width: 30%;
				padding-left: 15px;

				@media (max-width: 991px) {
					width: 100%;
					padding-left: 0;
					padding-bottom: 10px;
				}

				.form-group {
					margin-bottom: 0px;
				}
			}
		}

		.container {
			background: hsla(0, 0%, 100%, 0.8);
			padding: 10px;

			@media all and (min-width: 768px) and (max-width: 1199px) {
				padding: 20px;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			h4 {
				color: var(--Primarycolor);
				font-weight: 400;
				letter-spacing: .5px;
				font-size: 20px;
				line-height: 1.1em;
				margin: 0;
				padding: 0 0 10px 0;
				display: flex;
				width: 100%;
			}

			.top {
				display: flex;
				width: 100%;
				align-items: flex-start;

				.map-btn {
					a {
						background: var(--Primarycolor);
						color: #fff;
						height: 35px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 15px;
						font-size: 15px;
						text-decoration: none;
						border: 1px solid var(--Primarycolor);
						margin: 0 0 25px 0;
						width: 110px;

						&:hover {
							background: var(--Secondarycolor);
							border: 1px solid var(--Secondarycolor);
						}
					}
				}

				@media (max-width: 480px) {
					flex-wrap: wrap;
				}
			}



			.input-lg,
			select,
			input {
				padding: 0 15px;
				border-radius: 0;
				border: none;
				height: 40px;
				font-size: 16px;
				margin: 0 0 8px;
				background: #fff;
			}

			.checkbox {
				margin: 0;
				display: flex;
				align-items: center;
				padding: 0;

				label {
					font-size: 16px !important;
					font-weight: 500;
					padding: 0 0 0 25px;
					color: #000;

					&:before {
						margin: 3px 0 0 0;
					}

					&:after {
						left: 20px;
						top: 3px;
					}
				}

				input {
					height: auto;
					margin: 0;
				}
			}

			.bottom {
				padding: 0 0 20px 0;
				display: flex;

				.checkbox-success input[type=checkbox]:checked+label:before {
					background-color: var(--Primarycolor);
					border-color: var(--Primarycolor);
					outline: none !important;
				}

				@media (max-width: 768px) {
					flex-wrap: wrap;
					padding: 0;
				}
			}

		}

		&.search-banner {
			background-image: url(RESOURCE/img/search-banner1.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.form-control {
				margin-bottom: 5px;
			}
		}

		label[for="cb_pets"] {
			color: var(--whitecolor);
			font-size: 15px;
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;
		}

		.btn {
			margin: 2px;
		}

		.footer {
			.btn {
				background: var(--Primarycolor);
				color: #fff;
				border: 1px solid var(--Primarycolor);
				border-radius: 0;

				&:hover {
					background: var(--Secondarycolor);
					border: 1px solid var(--Secondarycolor);
				}
			}
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:991px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			border: 1px solid #ccc;
			position: relative;
			margin-bottom: 30px;
			background-color: var(--whitecolor);

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;

				figure {
					background-color: var(--Primarycolor);
					padding: 5px 10px;
					color: var(--whitecolor);
					font-weight: bold;
				}
			}

			.ratings {
				position: absolute;
				right: 15px;
				bottom: 13px;
				width: auto;
				background-color: rgb(85 1 31 / 70%);
				padding: 1px 5px;
				color: #fff;
			}

			.unit-title {
				color: #fff;
				color: var(--Primarycolor);
				padding: 10px;
				text-align: left;
				background-color: var(--Primarycolor);
				background-color: #ffff;
				position: relative;
				top: 0;
				width: 100%;
				z-index: 2;
				font-size: 16pt;
				font-weight: 400;
				min-height: auto;


				@media (max-width:767px) {
					font-size: 16px;
				}
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 10px;
					bottom: 5px;
					color: var(--Primarycolor);
					font-size: 24px;
					cursor: pointer;

					.fa {
						color: var(--whitecolor);
					}
				}

				a {
					display: block;
					position: relative;
					background: none;
					overflow: hidden;
					-webkit-transition: all 0.3s ease-out;
					transition: all 0.3s ease-out;

					img {
						display: block;
						position: relative;
						-webkit-transition: all 0.3s ease-out;
						transition: all 0.3s ease-out;
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						height: 400px;
						object-fit: cover;

						@media (max-width: 767px) {
							height: 320px;
						}

						@media (max-width: 575px) {
							height: 290px;
						}

						@media all and (min-width: 768px) and (max-width: 991px) {
							height: 300px;
						}

						@media all and (min-width: 992px) and (max-width: 1199px) {
							height: 350px;
						}
					}

					&:hover img {
						-webkit-transform: scale(1.1);
						transform: scale(1.1);
						opacity: 1;
					}

				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor) !important;
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				padding: 0 20px;
				height: 180px;
				margin-bottom: 18px;
				overflow: hidden;

				p {
					font-size: 15px;
					color: var(--defaultcolor);
					line-height: 25px;
					vertical-align: middle;
					overflow: hidden;
					margin: 10px 0;
				}
			}

			.unit-feat {
				background-color: var(--Primarycolor);


			}

		}
	}


	.map-pane {
		@mixin flex;
		height: 90vh !important;

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}

		}
	}

	.search-list-content {
		/* background: var(--greycolor); */

		.list-view {
			.map-col {
				display: block;

				.affix {
					position: fixed !important;
					width: 360px;
					top: 50px !important;

					.map-pane {
						padding: 0;
					}
				}
			}
		}
	}
}

.unit-box {
	.prices {
		.price-bl {
			padding: 10px 15px 10px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			background: #f4f4f4;
			font-size: 16px;
			flex-wrap: wrap;

			.Personen {
				margin: 0 0 0 10px;
			}
		}
	}

	.rentRate {
		.offer-price-box {
			padding: 10px 15px 10px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			background: #f4f4f4;
			font-size: 16px;
			flex-wrap: wrap;

			.discount-price {
				margin: 0 10px 0 0;
			}
		}
	}
}


.my-search-token {
	background-color: var(--whitecolor) !important;
	border-radius: 0 !important;
	padding: 0px 10px !important;
	border: 1px solid var(--Primarycolor) !important;

	.close {
		margin: 0 0 0 5px;
		color: var(--Primarycolor) !important;
		opacity: 1 !important;

	}
}

@media (max-width: 767px) {
	.vo-search-body {
		.search-result-properties {
			flex-wrap: wrap;
		}

		.results {
			.srow {
				width: 100%;
			}
		}
	}

	.result-pane {
		.result-header {
			.sorting-trigger {
				align-items: baseline !important;
			}
		}
	}

}

@media all and (min-width: 769px) and (max-width: 991px) {
	.vo-search-body {
		.search-bar {
			.container {
				.bottom {
					flex-wrap: wrap;
				}
			}
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.vo-search-body {
		.search-list-content {
			.list-view {
				.map-col {
					.affix {
						width: 293px;
					}
				}
			}
		}

		.map-pane {
			.gm-style {
				.gm-style-iw-c {
					max-width: 260px !important;
				}
			}
		}
	}
}