@import "setup.css";

.guestbox {
	.gb-td {
		padding: 8px 10px !important;
	}
}

.indexpage {

	.home-slider-section {
		position: relative;
	}

	.owl-carousel {
		z-index: 0;
	}

	.banner-slide {
		.slide1 {
			background-image: url(RESOURCE/img/slider-1.jpg);
		}

		.slide2 {
			background-image: url(RESOURCE/img/slider-2.jpg);
		}

		.slide3 {
			background-image: url(RESOURCE/img/slider-3.jpg);
		}

		.slide4 {
			background-image: url(RESOURCE/img/slider-4.jpg);
		}

		.slide5 {
			background-image: url(RESOURCE/img/slider-5.jpg);
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	.intro {
		position: absolute;
		z-index: 9;
		top: 0;
		left: 0;
		right: 0;
		margin: 150px 0 0 0;

		.hero {
			text-align: center;
			color: #fff;
			background: none;
			margin-bottom: 10px;
			padding-bottom: 0px;

			h1,
			h2 {
				font-family: Raleway, sans-serif;
				font-weight: 700;
				text-shadow: 0 0 10px #000;
				font-size: 30px;
				color: #fff;
				text-align: center;
				text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
			}

			h1 {

				font-family: 'Raleway', sans-serif;
				font-weight: 700;
				text-shadow: 0 0 10px #000;
				text-shadow: 0 0 10px #000;
				font-size: 45px;
				margin: 0 0 0 0;

				@media (max-width: 767px) {
					font-size: 28px;
					margin: 0px 0 0;
				}

			}

			h2 {
				font-size: 40px;
				font-family: 'Raleway', sans-serif;
				font-weight: 700;
				margin: 20px 0 0;
				text-shadow: 0 0 10px #000;

			}

		}
	}

	.item {
		background: #f7f7f7;
		/* min-height: 60vh; */
		min-height: 600px;
		margin: 0 0 0 0;
		border: 0px solid #dedede;
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: cover !important;
		width: 100%;

		img {
			width: 100%;
		}

		h3 {
			font-size: 18px;
			font-weight: 400;
			margin: 0 0 0 0;
			padding: 20px;
		}

		p {
			font-size: 15px;
			color: var(--defaultcolor);
			line-height: 22px;
			vertical-align: middle;
			overflow: hidden;
			margin: 0 0;
			padding: 0 20px 20px 20px;
			min-height: 220px;

			@media (max-width: 991px) {
				min-height: auto;
			}

			@media (min-width: 992px) and (max-width: 1199px) {
				min-height: 270px;
			}
		}

		.btn {
			margin: 0 0 20px 20px;
			border-radius: 0;
			height: 40px;
			padding: 0 20px;
			line-height: 40px;
			font-size: 15px;
			font-weight: 500;
			background: var(--PrimaryColor);
			border: 1px solid var(--PrimaryColor);
			-webkit-transition: all .3s ease;
			transition: all .3s ease;

			&:hover {
				background: var(--secondarycolor);
				border: 1px solid var(--secondarycolor);
			}
		}

		@media (max-width: 767px) {
			min-height: 560px;
		}


	}


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {

		min-height: 500px;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}


	.search2 {
		z-index: 9 !important;
	}

	.index-serchbar {
		padding: 0 15px;

		.container {
			position: relative;
			max-width: 1400px;
			width: 100%;

			.searchbar {

				margin-top: 20px;
				position: absolute;
				bottom: 100px;
				left: 0;
				right: 0;

				@media (max-width: 767px) {
					bottom: 50px;
				}

				h1 {
					color: #fff;
					margin-bottom: 10px;

					@media (max-width: 374px) {
						font-size: 28px;
					}
				}

				p {
					color: #fff;
					font-size: 18px;
					line-height: normal;
					margin-bottom: 25px;

					@media (max-width: 767px) {
						color: #fff;
						font-size: 15px;
						line-height: normal;
						margin-bottom: 15px;
					}
				}

				.searchbar-box {
					background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
					padding: 20px 30px;
					border-radius: 8px;
					background: url(RESOURCE/img/suchen-bg-1.jpg);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;

					@media (max-width: 767px) {
						padding: 15px 25px;
					}

					.row {
						display: flex;
						flex-wrap: wrap;
					}
				}

				.box {
					/*max-width: 200px;
			width: 100%;*/
				}

				h4 {
					font-size: 25px;
					color: #fff;
					margin: 0 0 10px 0;
					padding: 0 0 0 5px;
					width: 100%;

					@media (max-width: 374px) {
						font-size: 20px;
					}
				}

				.searchbox {
					padding: 7px;
					width: 50%;

					@media (max-width: 767px) {
						padding: 7px;
						width: 100%;
					}

					.input-lg {
						height: 40px;
					}

					.btn-group-lg>.btn,
					.btn-lg {
						padding: 7px 16px;
					}

					input,
					.search-btn,
					select {
						border-radius: 8px;
					}

					.search-btn {
						width: 100%;
						background: var(--Primarycolor);
						border-color: var(--Primarycolor);

						&:hover {
							background: var(--Secondarycolor);
							border-color: var(--Secondarycolor);
						}

					}

					.search-popup-trigger {
						cursor: pointer;
					}

					.form-control:disabled,
					.form-control[readonly] {
						background-color: white;
					}
				}

				@media (min-width:768px) {
					max-width: 650px;
				}

				@media (min-width:993px) {
					max-width: 600px;
				}

			}
		}
	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}
}

@media (max-width: 767px) {
	.indexpage {
		.searchbar {
			bottom: 50px;
		}

		.intro {
			margin: 130px 0 0;

			.hero {
				h2 {
					font-size: 20px;
					margin: 10px 0 0;
				}
			}
		}

		.searchbar {
			.searchbox {
				.input-lg {
					height: 40px;
				}

				.search-btn {
					height: 40px;
				}
			}

		}
	}
}